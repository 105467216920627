import PropTypes from 'prop-types';
import React from 'react';
import { Page } from '../components';

const ContentPage = (props = {}) => {
  const {
    pageContext = {},
  } = props;
  return (
    <div className="content">
      <Page {...pageContext} />
    </div>
  );
};

ContentPage.propTypes = {
  pageContext: PropTypes.shape({}).isRequired,
};

export default ContentPage;
